// Use commonly defined routes, and customize them here if needed.
// Import section functions
import { merge } from "lodash"
import {
  CASE_LAW as CASE_LAW_ROUTE,
  EU_STATUTE_CONSOLIDATED as EU_STATUTE_CONSOLIDATED_ROUTE,
  GOVERNMENT_BILL as GOVERNMENT_BILL_ROUTE,
  INTERNATIONAL_TREATY_CONSOLIDATED as INTERNATIONAL_TREATY_CONSOLIDATED_ROUTE,
  MODULE_OTHER_DOCUMENT_PDF as MODULE_OTHER_DOCUMENT_PDF_ROUTE,
  MODULE_STATUTE_CONSOLIDATED_PDF as MODULE_STATUTE_CONSOLIDATED_PDF_ROUTE,
  NEWS_ARTICLE as NEWS_ARTICLE_ROUTE,
  STATUTE_CONSOLIDATED as STATUTE_CONSOLIDATED_ROUTE,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT as SACP_ROUTE,
  SUPREME_COURT_PRECEDENT as SUPREME_COURT_PRECEDENT_ROUTE,
} from "@common/router/routes"
import { makeGroupRoute } from "@common/router/utils"

// Use customized material configuration for routes
// Do not change material configuration here
import { CASE_LAW } from "@/config/material-group"
import {
  DEFAULT_CONFIGURATION as DEFAULT_MATERIAL_CONFIGURATION,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  INTERNATIONAL_TREATY_CONSOLIDATED,
  MODULE_OTHER_DOCUMENT_PDF,
  MODULE_STATUTE_CONSOLIDATED_PDF,
  NEWS_ARTICLE,
  STATUTE_CONSOLIDATED,
  SUPREME_COURT_PRECEDENT,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
} from "@/config/material-type"

const euStatuteConsolidated = EU_STATUTE_CONSOLIDATED_ROUTE(EU_STATUTE_CONSOLIDATED)
const governmentBill = GOVERNMENT_BILL_ROUTE(GOVERNMENT_BILL)
const internationalTreatyConsolidated = INTERNATIONAL_TREATY_CONSOLIDATED_ROUTE(INTERNATIONAL_TREATY_CONSOLIDATED)
const newsArticle = NEWS_ARTICLE_ROUTE(NEWS_ARTICLE)
const moduleOtherDocumentPdf = MODULE_OTHER_DOCUMENT_PDF_ROUTE(MODULE_OTHER_DOCUMENT_PDF)
const moduleStatuteConsolidatedPdf = MODULE_STATUTE_CONSOLIDATED_PDF_ROUTE(MODULE_STATUTE_CONSOLIDATED_PDF)
const statuteConsolidated = STATUTE_CONSOLIDATED_ROUTE(STATUTE_CONSOLIDATED)
const supremeCourtPrecedent = SUPREME_COURT_PRECEDENT_ROUTE(SUPREME_COURT_PRECEDENT)
const supremeAdministrativeCourtPrecedent = SACP_ROUTE(SUPREME_ADMINISTRATIVE_COURT_PRECEDENT)

// Create group routes and their main route ("index")
const caseLaw = CASE_LAW_ROUTE({
  materialType: CASE_LAW.materialTypes,
  configuration: merge({}, DEFAULT_MATERIAL_CONFIGURATION, {
    listing: {
      caption: true,
      title: true,
      type: true,
      references: true,
    },
    sidebar: { search: true },
  }),
  title: CASE_LAW.label,
  description: "description.caseLaw",
}, { path: "" }) // Remove path from index as group has the path
const caseLawGroup = makeGroupRoute([
  caseLaw, // First item determines navigation item (label)
  supremeCourtPrecedent,
  supremeAdministrativeCourtPrecedent,
], {
  path: "oikeuskaytanto", // Prefix all routes with this route
  keepOnlyDocumentView: true, // Filter default routes the module does not use
})

const statuteGroup = makeGroupRoute([
  statuteConsolidated, // First item determines navigation item (label)
  euStatuteConsolidated,
  internationalTreatyConsolidated,
])

// Ordering here determines navigation ordering. Do NOT sort them alphabetically
// like everywhere else!
export default [
  statuteGroup,
  newsArticle,
  moduleOtherDocumentPdf,
  moduleStatuteConsolidatedPdf,
  governmentBill,
  caseLawGroup,
]
