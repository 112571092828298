import { merge } from "lodash"
import { makeDefaultMaterialRoute } from "@common/router/utils"
import PdfViewWrapper from "@wrappers/PdfViewWrapper.vue"
import editaConfig from "@/edita.config.js"

export default function(material, { highlight = false, isPublic = false } = {}) {
  return merge({}, makeDefaultMaterialRoute(material), {
    path: `muut-tiedostot-${editaConfig.serviceCode}-pdf`,
    children: [
      {
        path: ":documentKey/:documentLanguage?",
        name: "moduleOtherDocumentPdf.documentView",
        component: PdfViewWrapper,
        meta: { isPublic: !!isPublic },
        hidden: true, // Do not show in Nav
      },
    ],
    hidden: true,
    highlightItem: highlight,
  })
}
