import { merge } from "lodash"
import defaultConfig from "@common/config/edita.config.js"
import {
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  INTERNATIONAL_TREATY_CONSOLIDATED,
  NEWS_ARTICLE,
  STATUTE_AMENDMENT,
  STATUTE_CONSOLIDATED,
} from "@/config/material-type"

const SERVICE_CODE = "rajalex"

const CONFIG = merge({}, defaultConfig, {
  serviceCode: SERVICE_CODE,
  auth: {
    useAuthentication: true,
    requireAuthentication: true,
  },
  languages: [
    "fi",
    "sv",
  ],
  // Configurations for DS components' content
  // Use Vue component name for easier distinction.
  FrontPage: {
    auth: {
      blocks: [
        {
          type: "latest",
          materialType: [STATUTE_AMENDMENT.materialType],
          heading: "heading.statuteAmendment.frontpage",
          count: 6,
          view: "list",
          orientation: "vertical",
        },
        {
          type: "latest",
          onlyLanguages: ["fi"], // Do not show this in other languages
          materialType: [NEWS_ARTICLE.materialType],
          heading: "heading.newsArticle.frontpage",
          count: 5,
          view: "grid",
          orientation: "vertical",
        },
        {
          type: "latest",
          onlyLanguages: ["fi"], // Do not show this in other languages
          materialType: [GOVERNMENT_BILL.materialType],
          heading: "heading.governmentBill.index",
          count: 6,
          view: "list",
          orientation: "vertical",
        },
      ],
    },
    // guest: {
    //   blocks: [
    //     {
    //       text: [],
    //       type: "introduction",
    //       serviceCode: SERVICE_CODE,
    //     },
    //     {
    //       heading: "label.login",
    //       type: "login",
    //     },
    //   ],
    // },
  },
  PageHeader: {
    requireAuthentication: true,
    extraNav: [
      {
        meta: { title: "label.keywords" },
        name: "keywords",
      },
      {
        meta: { title: "label.instructions" },
        name: "module-other-document.documentView",
        navParams: { documentKey: "ohjeet" },
      },
    ],
  },
  PageFooter: {
    requireAuthentication: true,
    PageFooterBranding: {
      url: "https://www.raja.fi",
      name: "rajavartiolaitos",
      nameFull: "Rajavartiolaitos",
      target: "_blank",
      coop: true,
    },
  },
  SearchSuggest: [
    {
      type: "keyword",
      listTitle: "label.keyword",
      limit: 5,
      showDocumentCount: true,
    },
    {
      type: "document",
      listTitle: "label.statute",
      materialType: [
        STATUTE_CONSOLIDATED.materialType,
        EU_STATUTE_CONSOLIDATED.materialType,
        INTERNATIONAL_TREATY_CONSOLIDATED.materialType,
      ],
      limit: 5,
      showDocumentCount: false,
    },
  ],
})

export default CONFIG
