import {
  CASE_LAW, LAW_DRAFTING, LEGISLATION, NEWS,
} from "./material-group"

export default [
  {
    id: "all",
    label: "label.all",
    childGroups: [
      CASE_LAW.id,
      LAW_DRAFTING.id,
      LEGISLATION.id,
      NEWS.id,
    ],
    selected: true,
  },
  LEGISLATION,
  NEWS,
  LAW_DRAFTING,
  CASE_LAW,
]
