/* eslint-disable max-len */
// If necessary, translations can be exploded to separate files:
// - Splitting by language or
// - Splitting by context (documents, users, common service, etc)
import { merge } from "lodash"
import { common } from "@common/config/i18n"

export default merge({}, common, {
  fi: {
    // Customize translations here
    "service.name": "Rajalex",

    "description.caseLaw": "<b>Oikeuskäytäntö</b>-osio sisältää Rajalex-palvelun aihepiiriin kuuluvia korkeimman oikeuden ja korkeimman hallinto-oikeuden ratkaisuja.",
    "description.euStatuteConsolidated": "",
    "description.governmentBill": "<b>Hallituksen esitykset</b> -osio sisältää Rajalex-palvelusta löytyvien lakien muutoksiin ja Lakipalvelun teemoihin liittyvät uusien lakien hallituksen esitykset. Vanhemmat hallituksen esitykset löytyvät kronologisessa järjestyksessä vuosihakemistosta.",
    "description.newsArticle": "<b>Uutiset</b>-osio sisältää Rajalex Lakipalvelun teemoja koskevia Edilexin juridisen uutispalvelun uutisia. Uutiset on asiasanoitettu. <br/>Vanhemmat uutiset löytyvät kronologisessa järjestyksessä vuosihakemistosta.",
    "description.statuteConsolidated": "<b>Lainsäädäntö</b>-osio sisältää Rajavartiolaitoksen valitsemia säädöksiä ja määräyksiä, jotka on jaoteltu aihealueittain. Säädökset on asiasanoitettu.",

    "documentType.moduleOtherDocument": "",
    "documentType.moduleStatuteConsolidated": "Säädös",

    "label.moduleOtherDocument": "",
    "label.moduleStatuteConsolidated": "Säädös",

    "materialGroup.lawDrafting": "Hallituksen esitykset",
  },
  sv: {
    // Customize translations here
    "service.name": "Rajalex",

    "description.caseLaw": "<b>Rättspraxis</b>-sektionen innehåller avgöranden från högsta domstolen och högsta förvaltningsdomstolen som anknyter sig till Rajalex-tjänstens ämnesområde.",
    "description.euStatuteConsolidated": "",
    "description.governmentBill": "<b>Regeringens propositioner</b> -sektionen innehåller regeringens propositioner för nya lagar som hittas i Rajalex-tjänsten eller hänför sig till lagtjänstens teman.",
    "description.newsArticle": "<b>Nyheter</b>-sektionen innehåller den juridiska nyhetstjänsten Edilex nyheter gällande lagstiftningstjänstens teman. Nyheterna är försedda med ämnesord. Äldre nyheter hittas i kronologisk ordning ur årsarkivet.",
    "description.statuteConsolidated": "<b>Lagstiftning</b>-sektionen innehåller författingar och bestämmelser valda av Gränsbevakningsväsendet, och som är indelade i ämnesområden. Författningarna är försedda med ämnesord.",

    "documentType.moduleOtherDocument": "",

    "materialGroup.lawDrafting": "Regeringspropositioner",
  },
})
