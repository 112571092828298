import { merge } from "lodash"
import * as defaultMaterial from "@common/config/material-type"

const DEFAULT_CONFIGURATION = defaultMaterial.DEFAULT_CONFIGURATION

// Service specific modifications can be done here
const STATUTE_AMENDMENT_CUSTOM = {
  configuration: {
    sidebar: {
      // Override the detaul material type (statute-amendment) with something else
      updateInfo: defaultMaterial.STATUTE_CONSOLIDATED.materialType,
    },
  },
}
const NEWS_ARTICLE_CUSTOM = {
  // Example for overriding default material configuration
  title: "label.newsArticle",
}

const EU_STATUTE_CONSOLIDATED_CUSTOM = { configuration: { actions: { languages: true } } }
const STATUTE_CONSOLIDATED_CUSTOM = { configuration: { actions: { edilexReferenceCard: false } } }
const MODULE_STATUTE_CONSOLIDATED_PDF_CUSTOM = { configuration: { actions: { languages: true } } }

// Combine custom configuration to default
const EU_STATUTE_CONSOLIDATED = merge(
  {},
  defaultMaterial.EU_STATUTE_CONSOLIDATED,
  EU_STATUTE_CONSOLIDATED_CUSTOM
)
const GOVERNMENT_BILL = defaultMaterial.GOVERNMENT_BILL
const INTERNATIONAL_TREATY_CONSOLIDATED = defaultMaterial.INTERNATIONAL_TREATY_CONSOLIDATED
const MODULE_OTHER_DOCUMENT = defaultMaterial.MODULE_OTHER_DOCUMENT
const MODULE_OTHER_DOCUMENT_PDF = defaultMaterial.MODULE_OTHER_DOCUMENT_PDF
const MODULE_STATUTE_CONSOLIDATED_PDF = merge(
  {},
  defaultMaterial.MODULE_STATUTE_CONSOLIDATED_PDF,
  MODULE_STATUTE_CONSOLIDATED_PDF_CUSTOM
)
const NEWS_ARTICLE = merge(
  {},
  defaultMaterial.NEWS_ARTICLE,
  NEWS_ARTICLE_CUSTOM
)
const STATUTE_AMENDMENT = merge(
  {},
  defaultMaterial.STATUTE_AMENDMENT,
  STATUTE_AMENDMENT_CUSTOM
)
const STATUTE_CONSOLIDATED = merge(
  {},
  defaultMaterial.STATUTE_CONSOLIDATED,
  STATUTE_CONSOLIDATED_CUSTOM
)
const SUPREME_ADMINISTRATIVE_COURT_PRECEDENT = defaultMaterial.SUPREME_ADMINISTRATIVE_COURT_PRECEDENT
const SUPREME_COURT_PRECEDENT = defaultMaterial.SUPREME_COURT_PRECEDENT

const ALL = {
  [EU_STATUTE_CONSOLIDATED.materialType]: EU_STATUTE_CONSOLIDATED,
  [GOVERNMENT_BILL.materialType]: GOVERNMENT_BILL,
  [INTERNATIONAL_TREATY_CONSOLIDATED.materialType]: INTERNATIONAL_TREATY_CONSOLIDATED,
  [MODULE_OTHER_DOCUMENT.materialType]: MODULE_OTHER_DOCUMENT,
  [MODULE_OTHER_DOCUMENT_PDF.materialType]: MODULE_OTHER_DOCUMENT_PDF,
  [MODULE_STATUTE_CONSOLIDATED_PDF.materialType]: MODULE_STATUTE_CONSOLIDATED_PDF,
  [NEWS_ARTICLE.materialType]: NEWS_ARTICLE,
  [STATUTE_AMENDMENT.materialType]: STATUTE_AMENDMENT,
  [STATUTE_CONSOLIDATED.materialType]: STATUTE_CONSOLIDATED,
  [SUPREME_ADMINISTRATIVE_COURT_PRECEDENT.materialType]: SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
  [SUPREME_COURT_PRECEDENT.materialType]: SUPREME_COURT_PRECEDENT,
}

export {
  ALL,
  DEFAULT_CONFIGURATION,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  INTERNATIONAL_TREATY_CONSOLIDATED,
  MODULE_OTHER_DOCUMENT,
  MODULE_OTHER_DOCUMENT_PDF,
  MODULE_STATUTE_CONSOLIDATED_PDF,
  NEWS_ARTICLE,
  STATUTE_AMENDMENT,
  STATUTE_CONSOLIDATED,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
  SUPREME_COURT_PRECEDENT,
}
