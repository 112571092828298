import { render, staticRenderFns } from "./FeedbackWrapper.vue?vue&type=template&id=a25d3e34&scoped=true&lang=html&"
import script from "./FeedbackWrapper.vue?vue&type=script&lang=javascript&"
export * from "./FeedbackWrapper.vue?vue&type=script&lang=javascript&"


/* normalize component */
import normalizer from "!../../modules/rajalex/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a25d3e34",
  null
  
)

export default component.exports