
import {
  GOVERNMENT_BILL,
  EU_STATUTE_CONSOLIDATED,
  INTERNATIONAL_TREATY_CONSOLIDATED,
  MODULE_OTHER_DOCUMENT_PDF,
  MODULE_STATUTE_CONSOLIDATED_PDF,
  NEWS_ARTICLE,
  STATUTE_CONSOLIDATED,
  SUPREME_COURT_PRECEDENT,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
} from "./material-type"

const CASE_LAW = {
  id: "caseLaw",
  label: "materialGroup.caseLaw",
  materialTypes: [
    SUPREME_COURT_PRECEDENT.materialType,
    SUPREME_ADMINISTRATIVE_COURT_PRECEDENT.materialType,
  ],
  selected: true,
}

const LEGISLATION = {
  id: "legislation",
  label: "materialGroup.legislation",
  materialTypes: [
    STATUTE_CONSOLIDATED.materialType,
    EU_STATUTE_CONSOLIDATED.materialType,
    INTERNATIONAL_TREATY_CONSOLIDATED.materialType,
    MODULE_OTHER_DOCUMENT_PDF.materialType,
    MODULE_STATUTE_CONSOLIDATED_PDF.materialType,
  ],
  selected: true,
}

const LAW_DRAFTING = {
  id: "lawDrafting",
  label: "materialGroup.lawDrafting",
  materialTypes: [GOVERNMENT_BILL.materialType],
  selected: true,
}

const NEWS = {
  id: "news",
  label: "materialGroup.news",
  materialTypes: [NEWS_ARTICLE.materialType],
  selected: true,
}

export {
  CASE_LAW, LEGISLATION, NEWS, LAW_DRAFTING,
}
